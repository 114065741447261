import { Injectable } from "@angular/core";
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { Subject } from "rxjs";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";

@Injectable({
  providedIn: "root",
})
export class MediaServiceService {
  private eventCallback = new Subject<string>();
  returnImage = this.eventCallback.asObservable();

  base64Image: any;
  constructor(
    private camera: Camera,
    private androidPermissions: AndroidPermissions
  ) {}

  captureImage() {
    const options = {
      quality: 40,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
    };
    this.camera.getPicture(options).then(
      (imageData) => {
        this.base64Image = "data:image/jpeg;base64," + imageData;
        this.eventCallback.next(this.base64Image);
      },
      (err) => {
        if (err == "No Image Selected") {
          this.eventCallback.next("cancelled");
          return;
        }
        // Handle error
        this.androidPermissions
          .checkPermission(this.androidPermissions.PERMISSION.CAMERA)
          .then((result) => {
            if (result.hasPermission) {
              console.log("Has permission?", result.hasPermission);
            } else {
              this.androidPermissions.requestPermission(
                this.androidPermissions.PERMISSION.CAMERA
              );
            }
          });
      }
    );
  }

  gallery() {
    const options: CameraOptions = {
      quality: 40,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      cameraDirection: this.camera.Direction.BACK,
      correctOrientation: true,
      sourceType: 0,
      // targetWidth: 500,
      //  targetHeight: 500,
    };

    this.camera.getPicture(options).then(
      (imageData) => {
        this.base64Image = "data:image/jpeg;base64," + imageData;
        this.eventCallback.next(this.base64Image);
      },
      (err) => {
        // Handle error
        if (err == "No Image Selected") {
          this.eventCallback.next("cancelled");
          return;
        }
        this.androidPermissions
          .checkPermission(this.androidPermissions.PERMISSION.CAMERA)
          .then((result) => {
            if (result.hasPermission) {
              console.log("Has permission?", result.hasPermission);
            } else {
              this.androidPermissions.requestPermission(
                this.androidPermissions.PERMISSION.CAMERA
              );
            }
          });
      }
    );
  }
}
